<template>
  <div id="interviewDetail">
    <div class="clearfix"></div>
    <div class="ms-cell-interview">
      <div class="ms-cell-ft">
        <div class="ms-user">
          <div class="ms-user-avatar">
            <img v-if="interviewDetail.avatar" :src="interviewDetail.avatar" />
            <img v-else src="@/assets/img/live/userLogo.png" alt="" />
          </div>
          <div class="ms-user-name">
            {{
              interviewDetail.username ? interviewDetail.username : "匿名用户"
            }}
          </div>
          <div class="ms-user-time">{{ interviewDetail.createDate }}</div>
        </div>
      </div>

      <div style="line-height: 22px; margin: 10px 0">
        <div style="display: inline-block">
          <div class="listType">面经</div>
          <div class="listType blue">{{ interviewDetail.questions }}题</div>
        </div>
        <div class="ms-cell-data">
          <span style="margin-right: 12px">
            <span class="iconfont icon-liulan"></span
            >{{ interviewDetail.pageViews }}
          </span>

          <span style="margin-right: 12px"
            ><span
              class="iconfont icon-dianzan"
              style="margin-right: 4px"
            ></span
            >{{ interviewDetail.likeNums }}
          </span>
          <span>
            <span class="iconfont icon-huifu" style="margin-right: 4px"></span
            >{{ interviewDetail.commentNums }}
          </span>
        </div>
      </div>

      <div class="ms-cell-hd">
        <div class="ms-cell-tt">
          {{ interviewDetail.title }}
        </div>
      </div>
      <div class="ms-group">
        <div
          class="item"
          v-if="
            interviewDetail.interviewAfter &&
            interviewDetail.interviewAfter != ''
          "
        >
          <div class="ms-group-hd">【 面试经过 】</div>
          <div class="ms-group-bd">
            <p>
              {{ interviewDetail.interviewAfter }}
            </p>
          </div>
        </div>
        <div
          class="item"
          v-if="
            interviewDetail.interview &&
            interviewDetail.interview['0'] &&
            interviewDetail.interview['0'].length > 0
          "
        >
          <div class="ms-group-hd">【 HR筛选 】</div>
          <div class="ms-group-bd">
            <p
              v-for="(txt, index) in interviewDetail.interview['0']"
              :key="index"
            >
              {{ txt }}
            </p>
          </div>
        </div>
        <div
          class="item"
          v-if="
            interviewDetail.interview &&
            interviewDetail.interview['1'] &&
            interviewDetail.interview['1'].length > 0
          "
        >
          <div class="ms-group-hd">【 技术电面 】</div>
          <div class="ms-group-bd">
            <p
              v-for="(txt, index) in interviewDetail.interview['1']"
              :key="index"
            >
              {{ txt }}
            </p>
          </div>
        </div>
        <div
          class="item"
          v-if="
            interviewDetail.interview &&
            interviewDetail.interview['2'] &&
            interviewDetail.interview['2'].length > 0
          "
        >
          <div class="ms-group-hd">【 现场面试 】</div>
          <div class="ms-group-bd">
            <p
              v-for="(txt, index) in interviewDetail.interview['2']"
              :key="index"
            >
              {{ txt }}
            </p>
          </div>
        </div>
        <div
          class="item"
          v-if="
            interviewDetail.interview &&
            interviewDetail.interview['3'] &&
            interviewDetail.interview['3'].length > 0
          "
        >
          <div class="ms-group-hd">【 校园招聘会 】</div>
          <div class="ms-group-bd">
            <p
              v-for="(txt, index) in interviewDetail.interview['3']"
              :key="index"
            >
              {{ txt }}
            </p>
          </div>
        </div>
        <div
          class="item"
          v-if="
            interviewDetail.interview &&
            interviewDetail.interview['4'] &&
            interviewDetail.interview['4'].length > 0
          "
        >
          <div class="ms-group-hd">【 笔试 】</div>
          <div class="ms-group-bd">
            <p
              v-for="(txt, index) in interviewDetail.interview['4']"
              :key="index"
            >
              {{ txt }}
            </p>
          </div>
        </div>
        <div
          class="item"
          v-if="
            interviewDetail.interview &&
            interviewDetail.interview['5'] &&
            interviewDetail.interview['5'].length > 0
          "
        >
          <div class="ms-group-hd">【 视频面试 】</div>
          <div class="ms-group-bd">
            <p
              v-for="(txt, index) in interviewDetail.interview['5']"
              :key="index"
            >
              {{ txt }}
            </p>
          </div>
        </div>
        <div
          class="item"
          v-if="
            interviewDetail.interviewSummary &&
            interviewDetail.interviewSummary != ''
          "
        >
          <div class="ms-group-hd">【 面试总结 】</div>
          <div class="ms-group-bd">
            <p>
              {{ interviewDetail.interviewSummary }}
            </p>
          </div>
        </div>
        <div
          class="imageContainer"
          v-if="interviewDetail.pictures && interviewDetail.pictures.length > 0"
        >
          <el-image
            v-for="(image, index) in interviewDetail.pictures"
            style="width: 100px; height: 100px"
            :src="image"
            :key="index"
            @click="
              showIndex = index;
              showViewer = true;
            "
          />
        </div>
        <el-image-viewer
          v-if="showViewer"
          :initial-index="showIndex"
          :url-list="interviewDetail.pictures"
          :on-close="
            () => {
              showViewer = false;
            }
          "
          :z-index="99999"
        />
        <div class="ms-answer">
          <div class="ms-type" v-if="interviewDetail.labels">
            <span
              v-for="(label, lIndex) in interviewDetail.labels"
              :key="lIndex"
              :class="[
                lIndex == 0
                  ? 'orange'
                  : lIndex == 1
                  ? 'red'
                  : lIndex == 2
                  ? 'green'
                  : lIndex == 3
                  ? 'blue'
                  : '',
              ]"
            >
              {{ label }}
              <span style="padding: 0 5px">{{
                lIndex == interviewDetail.labels.length - 1 ? "" : "|"
              }}</span></span
            >
          </div>
        </div>
        <div style="text-align: center; margin: 10px 0">
          <el-button size="mini" @click="likeComments(0)">点赞</el-button>
        </div>
      </div>
    </div>
    <Comments :type="1" :comment-total="interviewDetail.commentNums"></Comments>
    <Reply
      v-if="userInfo && userInfo.id"
      :type="1"
      @reloadDetail="getInterviewDetail"
    ></Reply>
  </div>
</template>
<script>
import { getInterviewDetail, publishComment } from "@/service/company";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import Reply from "@/components/Reply";
import Comments from "@/components/Comments";
import { Toast } from "vant";
import { mapState } from "vuex";
export default {
  name: "InterviewDetail",
  data() {
    return {
      interviewDetail: {},
      showViewer: false,
      showIndex: 0,
    };
  },
  components: { ElImageViewer, Reply, Comments },
  methods: {
    getInterviewDetail() {
      getInterviewDetail(this.$route.query.id).then((res) => {
        if (res.success) {
          this.interviewDetail = res.result;
          document.title = `${this.interviewDetail.title} - 专注北美算法面试 - CSON`;
        }
      });
    },
    likeComments(commentId) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/wechatLogin");
        return;
      }
      const data = {
        type: false,
        parentId: commentId,
        superId: this.$route.query.id,
        superType: 1,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          Toast("点赞成功！");
          this.getInterviewDetail();
        } else {
          Toast("已点过赞");
        }
      });
    },
  },
  mounted() {
    this.getInterviewDetail();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
#interviewDetail {
  margin-top: 54px;
  background: #f8f8f8;
}
.ms-cell-interview {
  padding: 24px;
  width: calc(100% - 68px);
  margin: 10px;
  background: #fff;
}
.clearfix {
  clear: both;
  height: 1px;
}
.ms-user-avatar {
  width: 24px;
  height: 24px;
  display: inline-block;
  img {
    width: 100%;
    vertical-align: top;
  }
}
.ms-user-name {
  display: inline-block;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #34495e;
  line-height: 24px;
  margin-left: 6px;
  vertical-align: top;
}
.ms-user-time {
  display: inline-block;
  margin-left: 6px;
  vertical-align: top;
  line-height: 24px;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #898989;
}

.listType {
  display: inline-block;
  background: rgba(250, 100, 0, 0.2);
  border-radius: 2px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #fa6400;
  line-height: 18px;
  padding: 2px 8px;
  margin-right: 12px;
  &.blue {
    color: #409eff;
    background-color: #ecf5ff;
    border-color: #d9ecff;
  }
}
.ms-cell-data {
  float: right;
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #34495e;
  .iconfont {
    vertical-align: top;
  }
}
.ms-cell-tt {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #34495e;
  line-height: 20px;
  margin-bottom: 14px;
}
.ms-group-hd {
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #34495e;
  line-height: 12px;
}
.ms-group-bd {
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #969696;
  line-height: 16px;
}
.imageContainer {
  padding-top: 24px;
  padding-bottom: 24px;
}
::v-deep .imageContainer .el-image {
  margin-right: 12px;
  margin-bottom: 12px;
}
.ms-type {
  font-size: 12px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  line-height: 18px;
}
.orange {
  color: #fa6400 !important;
}

.blue {
  color: #2772db !important;
}

.green {
  color: #2ec1ac !important;
}

.red {
  color: #e02020 !important;
}
</style>