var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"interviewDetail"}},[_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"ms-cell-interview"},[_c('div',{staticClass:"ms-cell-ft"},[_c('div',{staticClass:"ms-user"},[_c('div',{staticClass:"ms-user-avatar"},[(_vm.interviewDetail.avatar)?_c('img',{attrs:{"src":_vm.interviewDetail.avatar}}):_c('img',{attrs:{"src":require("@/assets/img/live/userLogo.png"),"alt":""}})]),_c('div',{staticClass:"ms-user-name"},[_vm._v(" "+_vm._s(_vm.interviewDetail.username ? _vm.interviewDetail.username : "匿名用户")+" ")]),_c('div',{staticClass:"ms-user-time"},[_vm._v(_vm._s(_vm.interviewDetail.createDate))])])]),_c('div',{staticStyle:{"line-height":"22px","margin":"10px 0"}},[_c('div',{staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"listType"},[_vm._v("面经")]),_c('div',{staticClass:"listType blue"},[_vm._v(_vm._s(_vm.interviewDetail.questions)+"题")])]),_c('div',{staticClass:"ms-cell-data"},[_c('span',{staticStyle:{"margin-right":"12px"}},[_c('span',{staticClass:"iconfont icon-liulan"}),_vm._v(_vm._s(_vm.interviewDetail.pageViews)+" ")]),_c('span',{staticStyle:{"margin-right":"12px"}},[_c('span',{staticClass:"iconfont icon-dianzan",staticStyle:{"margin-right":"4px"}}),_vm._v(_vm._s(_vm.interviewDetail.likeNums)+" ")]),_c('span',[_c('span',{staticClass:"iconfont icon-huifu",staticStyle:{"margin-right":"4px"}}),_vm._v(_vm._s(_vm.interviewDetail.commentNums)+" ")])])]),_c('div',{staticClass:"ms-cell-hd"},[_c('div',{staticClass:"ms-cell-tt"},[_vm._v(" "+_vm._s(_vm.interviewDetail.title)+" ")])]),_c('div',{staticClass:"ms-group"},[(
          _vm.interviewDetail.interviewAfter &&
          _vm.interviewDetail.interviewAfter != ''
        )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"ms-group-hd"},[_vm._v("【 面试经过 】")]),_c('div',{staticClass:"ms-group-bd"},[_c('p',[_vm._v(" "+_vm._s(_vm.interviewDetail.interviewAfter)+" ")])])]):_vm._e(),(
          _vm.interviewDetail.interview &&
          _vm.interviewDetail.interview['0'] &&
          _vm.interviewDetail.interview['0'].length > 0
        )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"ms-group-hd"},[_vm._v("【 HR筛选 】")]),_c('div',{staticClass:"ms-group-bd"},_vm._l((_vm.interviewDetail.interview['0']),function(txt,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(txt)+" ")])}),0)]):_vm._e(),(
          _vm.interviewDetail.interview &&
          _vm.interviewDetail.interview['1'] &&
          _vm.interviewDetail.interview['1'].length > 0
        )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"ms-group-hd"},[_vm._v("【 技术电面 】")]),_c('div',{staticClass:"ms-group-bd"},_vm._l((_vm.interviewDetail.interview['1']),function(txt,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(txt)+" ")])}),0)]):_vm._e(),(
          _vm.interviewDetail.interview &&
          _vm.interviewDetail.interview['2'] &&
          _vm.interviewDetail.interview['2'].length > 0
        )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"ms-group-hd"},[_vm._v("【 现场面试 】")]),_c('div',{staticClass:"ms-group-bd"},_vm._l((_vm.interviewDetail.interview['2']),function(txt,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(txt)+" ")])}),0)]):_vm._e(),(
          _vm.interviewDetail.interview &&
          _vm.interviewDetail.interview['3'] &&
          _vm.interviewDetail.interview['3'].length > 0
        )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"ms-group-hd"},[_vm._v("【 校园招聘会 】")]),_c('div',{staticClass:"ms-group-bd"},_vm._l((_vm.interviewDetail.interview['3']),function(txt,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(txt)+" ")])}),0)]):_vm._e(),(
          _vm.interviewDetail.interview &&
          _vm.interviewDetail.interview['4'] &&
          _vm.interviewDetail.interview['4'].length > 0
        )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"ms-group-hd"},[_vm._v("【 笔试 】")]),_c('div',{staticClass:"ms-group-bd"},_vm._l((_vm.interviewDetail.interview['4']),function(txt,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(txt)+" ")])}),0)]):_vm._e(),(
          _vm.interviewDetail.interview &&
          _vm.interviewDetail.interview['5'] &&
          _vm.interviewDetail.interview['5'].length > 0
        )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"ms-group-hd"},[_vm._v("【 视频面试 】")]),_c('div',{staticClass:"ms-group-bd"},_vm._l((_vm.interviewDetail.interview['5']),function(txt,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(txt)+" ")])}),0)]):_vm._e(),(
          _vm.interviewDetail.interviewSummary &&
          _vm.interviewDetail.interviewSummary != ''
        )?_c('div',{staticClass:"item"},[_c('div',{staticClass:"ms-group-hd"},[_vm._v("【 面试总结 】")]),_c('div',{staticClass:"ms-group-bd"},[_c('p',[_vm._v(" "+_vm._s(_vm.interviewDetail.interviewSummary)+" ")])])]):_vm._e(),(_vm.interviewDetail.pictures && _vm.interviewDetail.pictures.length > 0)?_c('div',{staticClass:"imageContainer"},_vm._l((_vm.interviewDetail.pictures),function(image,index){return _c('el-image',{key:index,staticStyle:{"width":"100px","height":"100px"},attrs:{"src":image},on:{"click":function($event){_vm.showIndex = index;
            _vm.showViewer = true;}}})}),1):_vm._e(),(_vm.showViewer)?_c('el-image-viewer',{attrs:{"initial-index":_vm.showIndex,"url-list":_vm.interviewDetail.pictures,"on-close":() => {
            _vm.showViewer = false;
          },"z-index":99999}}):_vm._e(),_c('div',{staticClass:"ms-answer"},[(_vm.interviewDetail.labels)?_c('div',{staticClass:"ms-type"},_vm._l((_vm.interviewDetail.labels),function(label,lIndex){return _c('span',{key:lIndex,class:[
              lIndex == 0
                ? 'orange'
                : lIndex == 1
                ? 'red'
                : lIndex == 2
                ? 'green'
                : lIndex == 3
                ? 'blue'
                : '',
            ]},[_vm._v(" "+_vm._s(label)+" "),_c('span',{staticStyle:{"padding":"0 5px"}},[_vm._v(_vm._s(lIndex == _vm.interviewDetail.labels.length - 1 ? "" : "|"))])])}),0):_vm._e()]),_c('div',{staticStyle:{"text-align":"center","margin":"10px 0"}},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.likeComments(0)}}},[_vm._v("点赞")])],1)],1)]),_c('Comments',{attrs:{"type":1,"comment-total":_vm.interviewDetail.commentNums}}),(_vm.userInfo && _vm.userInfo.id)?_c('Reply',{attrs:{"type":1},on:{"reloadDetail":_vm.getInterviewDetail}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }